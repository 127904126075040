<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title class="text-h5">
            About
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12">
        <router-view />
        <h1>Select page to edit</h1>
      </v-col>

      <v-col cols="4">
        <v-card :color="stringToColour('index')" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5" v-text="'About'"></v-card-title>

              <v-card-actions>
                <v-btn
                  class="ml-2 my-2"
                  :to="`/pages/about/main`"
                  outlined
                  rounded
                  small
                >
                  Edit
                </v-btn>
                <v-btn
                  class="ml-2 my-2"
                  :href="`https://zgcc-eu.com/${getLanguage.lng}/about/`"
                  target="_blank"
                  color="green"
                  outlined
                  rounded
                  small
                >
                  preview
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col v-for="(item, i) in content" :key="i" cols="4">
        <v-card :color="stringToColour(item.about.slug)" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5" v-text="item.name"></v-card-title>

              <v-card-actions>
                <v-btn
                  class="ml-2 my-2"
                  :to="`/pages/about/${item.about.slug}/${item.aboutId}`"
                  outlined
                  rounded
                  small
                >
                  Edit
                </v-btn>
                <v-btn
                  class="ml-2 my-2"
                  :href="
                    `https://zgcc-eu.com/${getLanguage.lng}/about/${item.about.slug}`
                  "
                  target="_blank"
                  color="green"
                  outlined
                  rounded
                  small
                >
                  preview
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    content: {}
  }),

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    })
  },

  methods: {
    ...mapActions({
      getAllAboutData: "about/getAllAboutData"
    }),

    async loadData() {
      try {
        const { data } = await this.getAllAboutData({
          lng: this.getLanguage.lng
        });
        this.content = data;
      } catch (error) {
        console.error(error);
      }
    },

    stringToColour(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> 2) & 0xff;
        colour += ("00" + value).substr(-2);
      }
      return colour;
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>
